<template>
  <div>
    <div
    class="content-blank"
    v-on:click="survey.showSelector = true">
      <span>{{ $t("survey.select_question") }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'blank',
  data () {
    return {
      showSelector: true,
      showBlank: true
    }
  },
  computed: {
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    }
  }
}
</script>

<style lang="scss">

.content-blank {
  line-height: 350px;
  text-align: center;
  position: relative;
  width: 85%;
  margin: 0 auto;
  margin-top: 2%;
  cursor: pointer;
  position: relative;
  span {
    color: var(--splashColor);
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    box-shadow: 0 5px 15px 0 var(--headerBg);
    border: solid 1px var(--headerBg);
    border-radius: 6px;
  }
  // &:hover {
  //   span {
  //     box-shadow: 0 5px 15px 0 rgba(152, 113, 221, 0.5);
  //     border: solid 2px $color-purple;
  //     border-radius: 6px;
  //   }
  // }
}

</style>
